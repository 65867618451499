// src/slices/dashboardSlice.js
import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboard: {}, // Object to store dashboard with IDs as keys
  },
  reducers: {
    getDahboardKpis(state, action) {
      console.log('action.payload :>> ', action);
      state.dashboard = action.payload;
    },
  },
});

export const { getDahboardKpis } = dashboardSlice.actions;
export default dashboardSlice.reducer;
