// ** Reducers Imports
import { combineReducers } from 'redux';
import navbar from './navbar';
import layout from './layout';
import dashboard from './dashboard';   
import auth from './authentication';
import todo from '@src/views/apps/todo/store';
import chat from '@src/views/apps/chat/store';
import users from '@src/views/apps/employee/store';
import email from '@src/views/apps/email/store';
import kanban from '@src/views/apps/kanban/store';
import invoice from '@src/views/apps/invoice/store';
import calendar from '@src/views/apps/calendar/store';
import dataTables from '@src/views/tables/data-tables/store';
import permissions from '@src/views/apps/roles-permissions/store';

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  dashboard,
  dataTables,
  permissions,
});

export default rootReducer;
