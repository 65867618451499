import { createSlice } from "@reduxjs/toolkit";

const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  return item ? JSON.parse(item) : {};
};

const initialToken = () => {
  return window.localStorage.getItem("accessToken") || null;
};

const initialRefreshToken = () => {
  return window.localStorage.getItem("refreshToken") || null;
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
    token: initialToken(),
    refreshToken: initialRefreshToken(),
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload;
      state.token = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      localStorage.setItem("userData", JSON.stringify(action.payload));
      // localStorage.setItem('accessToken', action.payload.accessToken);
      // localStorage.setItem('refreshToken', action.payload.refreshToken);
    },
    handleLogout: (state) => {
      state.userData = {};
      state.token = null;
      state.refreshToken = null;
      localStorage.removeItem("userData");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    },
    setToken: (state, action) => {
      console.log("action.payload", action.payload);
      state.token = action.payload;
      localStorage.setItem("accessToken", action.payload);
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
      localStorage.setItem("refreshToken", action.payload);
    },
  },
});

export const { handleLogin, handleLogout, setToken, setRefreshToken } =
  authSlice.actions;

export default authSlice.reducer;
